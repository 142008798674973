import Error from "@@/common/Error.vue";
import MDRouter from "@/service/router";
import { getUpdateDetail } from '@/api/index';
import { mapState } from 'vuex';
import CompClientDetail from "../client/detail/_id.vue";
export default {
  computed: {
    ...mapState({
      userId: state => state.user.userId
    })
  },
  components: {
    CompClientDetail,
    Error
  },
  data() {
    return {
      showError: false,
      showMessage: '',
      update_id: '',
      update_info: {},
      user_info: {},
      product_info: {}
    };
  },
  async created() {
    window.userHeader.titleText("更新详情");
    const update_id = this.$route.params.id ? this.$route.params.id.replace('.html', '') : '';
    this.update_id = update_id;

    // 获取linkme链接
    const config = {
      type: 'update_detail',
      id: update_id
    };
    this.$store.dispatch('currentlLinkUrlAction', config);
    const res = await getUpdateDetail({
      update_id: this.update_id
    });
    const _this = this;
    if (res.status == 0) {
      this.update_info = res.data;
      this.user_info = res.data.user_info;
      this.product_info = res.data.product_info;
    } else {
      this.showError = true;
      if (res.message == '您没有权限查看此更新') {
        this.showMessage = res.message + (_this.userId ? '' : '，请登录后重试');
      } else {
        this.showMessage = res.message || '';
      }
    }
    // eslint-disable-next-line no-undef
    userHeader.getMdlink({
      type: 'update_detail',
      id: this.update_id
    });
  },
  methods: {
    // 去项目详情
    goPro() {
      MDRouter.goProInfo(this.update_info.pro_id);
    },
    toUserhome(item) {
      MDRouter.toUserhome(item.id);
    }
  }
};